import React from "react"
import styled from "styled-components"
import { medWrapper } from "../../styles/helpers"
import Wysiwyg from "./Wysiwyg"

const MainPageContent = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <Wysiwyg data={data} />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }
`

export default MainPageContent
