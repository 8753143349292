import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleIntro from "../components/defaultTemplate/TitleIntro"
import MainPageContent from "../components/defaultTemplate/MainPageContent"

const DefaultPage = props => {
  const titleIntro = props?.data?.titleIntro?.template?.defaultTemplate
  const mainPageContent =
    props?.data?.mainPageContent?.template?.defaultTemplate
      .mainPageContentWysiwyg
  return (
    <Layout>
      <Seo title="Lucid Total Rewards" />
      <TitleIntro data={titleIntro} />
      <MainPageContent data={mainPageContent} />
    </Layout>
  )
}

export const defaultQuery = graphql`
  query TempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    titleIntro: wpPage(id: { eq: $id }) {
      template {
        ... on WpDefaultTemplate {
          defaultTemplate {
            titleIntroTitle
            titleIntroContent
          }
        }
      }
    }

    mainPageContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpDefaultTemplate {
          defaultTemplate {
            mainPageContentWysiwyg
          }
        }
      }
    }
  }
`

export default DefaultPage
